<template>
  <div class="video">
    <div v-if="$screenWidth>1200">
      <video-player class="vjs-custom-skin" :options="playerOptions"></video-player>
      <div class="right">
        <div class="teacher">
          <el-avatar
            src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"></el-avatar>
          <span style="margin-left: 10px;">教练</span>
        </div>
        <div class="course">
          <span style="text-align: left;">相关活动</span>
          <div
            class="item" v-for="(item,index) in dataList"
            :key="index"
            @click="playerOptions.sources[0].src = item.videoUlr">
            <el-image
              src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"></el-image>
            <div class="item_right">
              <span class="title">{{item.name}}</span>
              <div class="bottom">
                <span class="level">高级</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="mobile">
      <video-player class="vjs-custom-skin" :options="playerOptions"></video-player>
      <div class="right">
        <div class="teacher">
          <el-avatar
            src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"></el-avatar>
          <span style="margin-left: 10px;">教练</span>
        </div>
        <div class="course">
          <span style="text-align: left;">相关活动</span>
          <div
            class="item" v-for="(item,index) in dataList"
            :key="index"
            @click="playerOptions.sources[0].src = item.videoUlr">
            <el-image
              src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"></el-image>
            <div class="item_right">
              <span class="title">{{item.name || 'title'}}</span>
              <div class="bottom">
                <span class="level">高级</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default{
    data(){
      return{
        dataList:[],
        playerOptions: {
          playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度

          autoplay: false, //如果true,浏览器准备好时开始回放。

          controls: true, //控制条

          preload: 'auto', //视频预加载

          muted: false, //默认情况下将会消除任何音频。

          loop: false, //导致视频一结束就重新开始。

          language: 'zh-CN',

          aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）

          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。

          sources: [{
            type: 'video/mp4',

            //src: require("http://img.yopoo.cn/banner_video.mp4")

            src: 'http://img.yopoo.cn/banner_video.mp4' //你所放置的视频的地址，最好是放在服务器上

          }],

          //poster: "http://39.106.117.192:8080/static/indexImg.png", //你的封面地址（覆盖在视频上面的图片）

          width: document.documentElement.clientWidth,

          notSupportedMessage: '此视频暂无法播放，请稍后再试' //允许覆盖Video.js无法播放媒体源时显示的默认信息。

        },
      }
    },
    created() {
      console.log({query:this.$route.params.uuid})
      this.getListCatalog()
    },
    methods:{
      async getListCatalog(){
        let res = await this.$http.get('/official/lesson/catalog',{
          params:{uuid:this.$route.params.uuid}
        })
        // let res = await this.$http.get('/app/train/listCatalog',{
        //   params:{tuuid:this.$route.params.uuid}
        // })
        console.log({getListCatalog:res})
        let play = res.data.data.find(item=>{
          return item.px == this.$route.params.px
        })
        this.playerOptions.sources[0].src = play.videoUlr
        res.data.data.sort((small,big)=>{
          return small.px - big.px
        })
        console.log(res.data.data)
        this.dataList = res.data.data


      }
    }
  }
</script>

<style scoped lang="scss">
  .video{
    // height: 48vw;
    @media screen and (min-width:1201px) {
      height: 48vw;
      display: flex;
    }
    .vjs-custom-skin{
      width: 80vw;
      ::v-deep .video-js.vjs-fluid, .video-js.vjs-16-9, .video-js.vjs-4-3{
        height: 100%;
      }
    }
    .right{
      width: 20vw;
      height: 100%;
      background-color: #1c1f21;
      color: #FFFFFF;
      // overflow: hidden;
      overflow-y: scroll;
      .teacher{
        display: flex;
        align-items: center;
        padding: 20px;
        .el-avatar{
          width: 60px;
          height: 60px;
        }
      }
      .course{
        display: flex;
        flex-direction: column;
        padding: 20px;
        .item{
          display: flex;
          margin: 10px 0;
          cursor: pointer;
          .el-image{
            width: 96px;
            height: 64px;
            border-radius: 8px;
          }
          .item_right{
            display: flex;
            flex-direction: column;
            font-size: 14px;
            color: rgba(255,255,255,.6);
            margin-left: 10px;
            .bottom{
              font-size: 12px;
              color: rgba(255,255,255,.4);
              text-align: left;
            }
          }
        }
      }
    }

    .mobile{
      .vjs-custom-skin{
        width: 100vw;
      }
      .right{
        width: 100vw;
      }
    }
  }

</style>
